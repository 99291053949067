import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Card, Placeholder, Button,Dropdown,Pagination   } from 'react-bootstrap';
// import { generateCustomPlaceholderURL,generatePhotoPlaceholderURL  } from 'react-placeholder-image';
import { getReadableStringOfDate,getCurrentDateTime,getReadableStringOfVariable } from '../../utils/generalHelper';
//Markdown-Parser
import ReactMarkdown from 'react-markdown';
const axios = require('axios');
const qs = require('qs');

//Load Styles
import styles from '../../styles/components/concerts/Concerts.module.scss'
import variables from '../../styles/variables.module.scss';
//i18n
import { useTranslation } from 'react-i18next';


function Concerts({ getNextXConcerts, showFilter,showSorting,limitToNumber,sortingMechanism,showOnlyPastConcerts,showOnlyFutureConcerts }) 
{
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [showOnlyPastConcertsUIFlag, setShowOnlyPastConcerts] = useState(showOnlyPastConcerts);
  
  // const [pageCount, setPageCount] = useState(1);
  // const [totalEntries, setTotalEntries] = useState(1);
  const [selectedSortingMechanism, setSelectedSortingMechanism] = useState(sortingMechanism ? sortingMechanism : "date:asc");
  const [paginationItems, setPaginationItems] = useState([]);

  const fetchData = async () => {
    
    try {
      let response = {};
      const currentDateTime = getCurrentDateTime('');
      if(limitToNumber > 0)
      {
       const url = `/api/backend/concert/concert?locale=${i18n.language}&sorting=${selectedSortingMechanism}&${`paginationPage=${selectedPage}&paginationPageSize=${pageSize}`}&${`currentDateTime=${currentDateTime}`}&${`limitResult=${true}`}&${`limitToNumber=${limitToNumber}`}`;
      
       response = await fetch(url, {
          headers: {
            Accept: "application/json",
          },
        });
        // setSelectedPage(1);
      }
      else if(limitToNumber > 0)
      {
        const url = `/api/backend/concert/concert?locale=${i18n.language}&sorting=${selectedSortingMechanism}&${`paginationPage=${selectedPage}&paginationPageSize=${pageSize}`}&${`currentDateTime=${currentDateTime}`}`;
        response = await fetch(url, {
            headers: {
              Accept: "application/json",
            },
        });
      }
      else if(showOnlyPastConcerts == true)
      {
      
        const url = `/api/backend/concert/concert?locale=${i18n.language}&sorting=${selectedSortingMechanism}&${`paginationPage=${selectedPage}&paginationPageSize=${pageSize}`}&${`currentDateTime=${currentDateTime}`}&${`showOnlyPastConcerts=${true}`}&${`showOnlyFutureConcerts=${false}`}`;
        response = await fetch(url, {
            headers: {
              Accept: "application/json",
            },
        });
      }
      else if(showOnlyFutureConcerts == true)
      {
  
        const url = `/api/backend/concert/concert?locale=${i18n.language}&sorting=${selectedSortingMechanism}&${`paginationPage=${selectedPage}&paginationPageSize=${pageSize}`}&${`currentDateTime=${currentDateTime}`}&${`showOnlyPastConcerts=${false}`}&${`showOnlyFutureConcerts=${true}`}`;
        response = await fetch(url, {
            headers: {
              Accept: "application/json",
            },
        });
      }
      else
      {

        const url = `/api/backend/concert/concert?locale=${i18n.language}&sorting=${selectedSortingMechanism}&${`paginationPage=${selectedPage}&paginationPageSize=${pageSize}`}`;

        response = await fetch(url, {
          headers: {
            Accept: "application/json",
          },
        });
      }
    
      const fetchedData = await response.json();      
      setData(fetchedData.data);    
      // setOldSelectedBlogCategory(selectedBlogCategory);
      // setPageCount(fetchedData.meta.pagination.pageCount);
      // setTotalEntries(fetchedData.meta.pagination.total);
      setPaginationItems(calculatePagination(fetchedData.meta.pagination.pageCount));
      
    } catch (error) {

      console.log('[Error - Concerts Component] - ' + error);
      
    }
    finally{
      setIsLoading(false)
    }
  };

  function calculatePagination(count){

    let active = selectedPage;
    let items = [];
    for (let number = 1; number <= count; number++) {
      items.push(
        <Pagination.Item 
        className={styles['pagination-item']}
        key={number} 
        active={number === active}
        onClick={()=> setSelectedPage(number)}>
          {number}
        </Pagination.Item>,
      );
    }

    return items;
  }

  useEffect(() => {
    //Simulate long loading in miliseconds
    //For Production Usage set to Minimum! 
    setTimeout(() => setIsLoading(false), 100);

      async function fetchDataFromNextAPI() {           
        fetchData();             
      }      
      
      fetchDataFromNextAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[i18n.language,selectedSortingMechanism,selectedPage])

  if (isLoading===true) return null;

  return (
    <React.Fragment>  
    <Container className="pt-4" >
      {
           isLoading === false ?

           data.map((concertEntry) => (
        <Row key={concertEntry.id.toString()} 
        className={`justify-content-center ${styles["concertEntry"]}`}
        style={{
          backgroundColor:variables.initial_standardHeaderBanner_backgroundColor,
          color:variables.initial_standardHeaderBanner_fontColor,
          // fontFamily:"CintaSehatti",
        }}>
        <Col  xs={'4'} sm={'4'} md={'4'}  lg={'2'}  xl={'2'} >
          <div >
            <span className={styles['dayDate']}>{`${getReadableStringOfDate(concertEntry.attributes.concertDate,'dd')}`}.{`${getReadableStringOfDate(concertEntry.attributes.concertDate,'LL')}`}</span><br />
            {/* <span className={styles['monthDate']}>{`${getReadableStringOfDate(concertEntry.attributes.concertDate,'LLLL').toString().toUpperCase()}`}</span><br />
            <span className={styles['monthDate']}>{`${getReadableStringOfDate(concertEntry.attributes.concertDate,'LL').toString().toUpperCase()}`}</span><br /> */}
            <span className={styles['yearDate']}>{`${getReadableStringOfDate(concertEntry.attributes.concertDate,'yyyy').toString().toUpperCase()}`}</span><br />
          </div>
        </Col>
        <Col  xs={'8'} sm={'8'} md={'8'}  lg={'10'}  xl={'10'} >
          <div >
            <span>{concertEntry && concertEntry.attributes && concertEntry.attributes.description &&
    `${getReadableStringOfVariable(concertEntry.attributes.description)}`}</span><br />
          </div>
        </Col>
        <Col xs={{ span: 8, offset: 4 }} sm={{ span: 8, offset: 4 }} md={{ span: 8, offset: 4 }}  lg={{ span: 10, offset: 2 }}  xl={{ span: 10, offset: 2 }}>
          <div >
            {
              concertEntry.attributes.hide_location == false || concertEntry.attributes.hide_location == undefined ?
              <>
              {
                concertEntry.attributes.location.data.attributes.url != null ?
                <>
                <a href={concertEntry.attributes.location.data.attributes.url} target='_blank' without="true" rel="noreferrer">
                  <span>{concertEntry && concertEntry.attributes && concertEntry.attributes.location && concertEntry.attributes.location.data && concertEntry.attributes.location.data.attributes &&
            `${getReadableStringOfVariable(concertEntry.attributes.location.data.attributes.name)}`}</span><br />
                   
                </a>
                 <span>{concertEntry && concertEntry.attributes && concertEntry.attributes.location && concertEntry.attributes.location.data && concertEntry.attributes.location.data.attributes &&
                  `${getReadableStringOfVariable(concertEntry.attributes.location.data.attributes.address)}`}</span>
                  </>
                :
                <>
                <span>{concertEntry && concertEntry.attributes && concertEntry.attributes.location && concertEntry.attributes.location.data && concertEntry.attributes.location.data.attributes &&
            `${getReadableStringOfVariable(concertEntry.attributes.location.data.attributes.name)}`}</span><br />
                    <span>{concertEntry && concertEntry.attributes && concertEntry.attributes.location && concertEntry.attributes.location.data && concertEntry.attributes.location.data.attributes &&
            `${getReadableStringOfVariable(concertEntry.attributes.location.data.attributes.address)}`}</span></>
              }

           {
          showOnlyPastConcertsUIFlag == false && concertEntry.attributes.ticketUrl ? 
          <div style={{minWidth: '100px'}}>
            
           
              {/* Show an Icon for the Ticket and the URL Behind */}
              
              {
              concertEntry.attributes.soldout === true ? 
             
                <p style={{color:'black'}}>sold out</p>
               
                : 
                <>
                {/* <FontAwesomeIcon style={{color:'green'}} className={styles['ticketIcon']}  icon="fa-solid fa-ticket" title="Tickets verfügbar"/> */}
                <a className='ticketURL' without="true" rel="noreferrer" href={concertEntry.attributes.ticketUrl} target='_blank' >
                <Image 
                      height={`50px`}
                      width={`50px`}
                    alt={"Tickets bestellen"}
                    src={"/assets/icons/common/ticket2.svg"}
                  />
                  </a>
                </>
              }
              {/* Tickets kaufen */}
            <br />
          </div>
          : null
        }
            </>
            : null
            }
          </div>
        </Col>
     
      </Row>  
          ))
          :
          <></>
        }
    </Container>
     <Container>
     <Row className='justify-content-center'>
       <Col></Col>
       <Col xs={'auto'} sm={'auto'} md={'auto'}  lg={'auto'}  xl={'auto'}>
           <Pagination>{paginationItems}</Pagination>
       </Col>
       <Col></Col>

     </Row>
   </Container>
   </React.Fragment>
  )
}

export default Concerts;