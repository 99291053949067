const { DateTime } = require("luxon");

// This is Browser Code so it can be debugged in the browser
function getImageMetadata(imageURL) {
  const img = new Image();
  img.src = imageURL;

  return { width: img.width, height: img.height };
}

function getImageHeight(imageUrl){
  try 
  {
   
    const height = getImageMetadata(imageUrl).height;
      
    return height;

  } 
  catch (error) {
     
    throw error;
  }
  
}
function getImageWidth(imageUrl){
  try 
  {
   
    const width = getImageMetadata(imageUrl).width;
      
    return width;

  } 
  catch (error) {
     
    throw error;
  }
  
}

function getReadableStringOfDate(dateTime, format){
    try 
    {
        //see Examples of how formatting can be done here: https://moment.github.io/luxon/#/formatting?id=toformat
        const currentDate = DateTime.fromISO(dateTime, {zone: "Europe/Vienna",locale: "de-AT" });       
        
        let formattedDate = currentDate.toFormat(format);
        
      return formattedDate;
  
    } 
    catch (error) {
       
      throw error;
    }
    
  }

  function getReadableStringOfVariable(variable){
    try 
    {
      console.log(variable);
        let stringToReturn = variable;


        
        return stringToReturn;
  
    } 
    catch (error) {
       
      console.log(error);
      return "value could not be loaded."
    }
    
  }

  function getCurrentDateTime(format){
    try 
    {
      
        //see Examples of how formatting can be done here: https://moment.github.io/luxon/#/formatting?id=toformat
        const currentDateAsISO = DateTime.now().toISO();
        const currentDate = DateTime.fromISO(currentDateAsISO, {zone: "Europe/Vienna",locale: "de-AT" });       
        const currentDateUTC = currentDate.toUTC().toISO();
              
        let formattedDate = "";

        if(format !== '')
        {
         formattedDate = currentDate.toFormat(format);
        }
        else
        {
          formattedDate = currentDateUTC;
        }
        
      return formattedDate;
  
    } 
    catch (error) {

       console.log('Error occurred when trying to get the current DateTime in a specific format');
       console.log(error);
      throw error;
    }
    
  }

module.exports = {
  getReadableStringOfDate,
  getCurrentDateTime,
  getReadableStringOfVariable,
  getImageHeight,
  getImageWidth,
}