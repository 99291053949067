import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/elements/QuickLinkBox.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function QuickLinkBox (props){
  const [ showGoTop, setShowGoTop ] = useState(false)//--> use for production//useState is the Hook
 // const [isHovered, setIsHovered] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [clickCounts, setClickCounts] = useState({});
  const [lastClickedElementId, setLastClickedElementId] = useState("");
  const [currentlyHoveredButton, setCurrentlyHoveredButton] = useState(null);

  const handleTouchStart = (id, href)  => (event) =>   {

    // id = i.e. music
    let clickHistory = JSON.parse(sessionStorage.getItem('clickHistory')) || [];

    // Add the current buttonId to the click history
    clickHistory.push({timestamp: new Date().getTime(), buttonId: id});

    // Ensure the click history has at most two elements
    if (clickHistory.length > 2) {
        clickHistory.shift(); // Remove the oldest element
    }
    // Save the updated click history back to session storage
    sessionStorage.setItem('clickHistory', JSON.stringify(clickHistory));

    let latestClickedButton = clickHistory[clickHistory.length - 1].buttonId;
    let latestClickedButtonTS = new Date(clickHistory[clickHistory.length - 1].timestamp);
    let previousClickedButton =  (clickHistory.length === 2 ? clickHistory[0].buttonId : "None");
    let previousClickedButtonTS = new Date(clickHistory[0].timestamp);

    // wenn 2 mal 
    if(previousClickedButton === id)
    {
        setClickCounts({});
        window.location.href = href;
    }
    else
    {   

    const currentCount = clickCounts[id] || 0;
    const updatedCounts = { ...clickCounts, [id]: currentCount + 1 };
  

    

    setClickCounts(updatedCounts);


    if (currentCount === 0) {
      // First click, trigger hover animation
      setActiveButton(id);
      // sessionStorage.setItem("CurrentlyActiveButton",id);

 
      setTimeout(() => {  
       
        // let currentlyHoveredButtonFromSession = sessionStorage.getItem("CurrentlyHoveredButton");

        let clickHistory = JSON.parse(sessionStorage.getItem('clickHistory')) || [];

        if(clickHistory.length > 0)
        {
          let latestClickedButton = clickHistory[clickHistory.length - 1].buttonId;
          let latestClickedButtonTS = new Date(clickHistory[clickHistory.length - 1].timestamp);
       


        let previousClickedButton =  (clickHistory.length === 2 ? clickHistory[0].buttonId : "None");
        let previousClickedButtonTS = new Date(clickHistory[0].timestamp);
       
          clickHistory.shift(); // Remove the oldest element
      
        // Save the updated click history back to session storage
        sessionStorage.setItem('clickHistory', JSON.stringify(clickHistory));



        // When to automatically reset the Active Button

        let calculatedTimeDelta = new Date().getTime() - latestClickedButtonTS;
    
        
        if(calculatedTimeDelta >= 5000)
        {
            setActiveButton(null);
            updatedCounts[id] = 0
            setClickCounts(updatedCounts) 
        }
        else if(latestClickedButton !== previousClickedButton)
        {
            // do not abort
        }
        else{
            // sessionStorage.setItem("PreviousClickedButton",null);
            setActiveButton(null);
            // setLastClickedElementId("");
            updatedCounts[id] = 0
            setClickCounts(updatedCounts) 
          }
        } }, 5000
      ); // Reset active button after X ms



      Object.keys(clickCounts).forEach(key => {
        updatedCounts[key] = (key === id) ? (clickCounts[key] || 0) + 1 : 0;
      });

      // sessionStorage.setItem("PreviousClickedButton",id);
      
    } else {
      clickCounts[id];
      // sessionStorage.setItem("PreviousClickedButton",id);
      // Second click, navigate to the desired URL
      window.location.href = href;
    }
  }
  };

  const handleVisibleButton = () => {

    setShowGoTop( window.pageYOffset > 50 )
}

const handleScrollUp = () => {
  window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
}

  useEffect( () => {
    sessionStorage.setItem('clickHistory', null);
    window.addEventListener( 'scroll', handleVisibleButton )
}, [] );


  return (
    <React.Fragment >
      <div className={`${styles.specialButtons}`}>
        <a id="concerts" onClick={handleTouchStart('concerts', '/concerts')} className={activeButton === 'concerts' ? styles.hovered : ''}>
        <FontAwesomeIcon icon="fa-solid fa-ticket"  className={styles.theIconItself}/>
          <i className="icon-katalog"></i>Tickets & Konzerte
        </a>
        <a id="music" onClick={handleTouchStart('music', '/music')} className={activeButton === 'music' ? styles.hovered : ''}>
          <FontAwesomeIcon icon="fa-solid fa-music" className={styles.theIconItself}/>
          <i className="icon-haus"></i> Musik durchstöbern

        </a>
        <a id="contact" onClick={handleTouchStart('contact', '/contact')} className={activeButton === 'contact' ? styles.hovered : ''}>
          <FontAwesomeIcon icon="fa-solid fa-address-book" className={styles.theIconItself}/>
          Booking & Kontakt
        </a>
        <a
          href="#top"
          title="Nach Oben"
          className={`${styles.slideUp} ${showGoTop === true ? styles.showAnker : ''}` }
          onClick={ handleScrollUp }
        >
          <FontAwesomeIcon icon="fa-solid fa-arrow-up" className={styles.theGoTopIcon}/>
        </a>
      </div>
    </React.Fragment>
  );
}


export default QuickLinkBox;